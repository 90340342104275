/*

? Let's import the necessary redux components

*/

import { ThunkAction, configureStore, createSlice } from "@reduxjs/toolkit";
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { Action } from "redux";

/*

? And then, we'll import the default global state data and it's corresponding interface

*/

/*

/*

? Now we'll import all the reducers we're going to use in this app

*/

import {
	AddHonorsAndAwardsDetailsForUser,
	AddLicenseAndCertificationDetailsForUser,
	AddSaveEntityBusinessProfile,
	AddSaveEntityUserProfile,
	AddSepcialOfferDetailsForBusinessProfile,
	AddUserCourses,
	AddUserLanguage,
	AddingBeegruPointsForShareService,
	AllowcopyProperty,
	CancelPropertyBoostState,
	CancelSubscription,
	CreateNewBugReport,
	CreateNewComments,
	CreateNewCommentsUserPost,
	CreateNewPost,
	CreateNewPostUser,
	CreateNewReport,
	CreateNewReview,
	CreateNewService,
	CreateNewShares,
	CreateNewSubscription,
	CreateNewView,
	CreatePropertyBoostState,
	EmployeeRequest,
	FaqAddDownvote,
	FaqAddUpvote,
	FaqRemoveDownvote,
	FaqRemoveUpvote,
	FetchBusinessDashboardAnalyticsGraph,
	FetchBusinessDashboardAnalyticsGraphCustomDates,
	FetchBusinessDashboardAnalyticsLeadsThisWeek,
	FetchBusinessDashboardAnalyticsListingViewsThisWeek,
	FetchBusinessDashboardAnalyticsMostLeadsGeneratingListing,
	FetchBusinessDashboardAnalyticsMostSavedListing,
	FetchBusinessDashboardAnalyticsMostViewedListing,
	FetchBusinessDashboardAnalyticsProfileViews,
	FetchMoreBusinessProfiles,
	KycProfileTypes,
	LoadingMoreBusinessProfileGivenReviews,
	LoadingMoreBusinessProfilePosts,
	LoadingMoreBusinessProfileReviews,
	LoadingMoreUserGivenReviews,
	LoadingMoreUserProfilePosts,
	LoadingMoreUserReviews,
	NewEmployeeRquestOnBoarding,
	OnBoarding,
	ProfileToUpgrade,
	ProfileToUpgradeId,
	ProfileToUpgradeType,
	RemoveBooms,
	RenewSubscription,
	ReplyToComment,
	SetDefaultLocation,
	SetDefaultLocationBoost,
	SetDefaultLookingFor,
	SetPropertyBoostDataState,
	SetPropertyBoostDetailState,
	SetPropertyBoostLocationState,
	// PropertyBoost
	SetPropertyBoostMobileStepState,
	SetPropertyBoostModalState,
	SetPropertyBoostPointsState,
	SetPropertyBoostSnackState,
	SetPropertyBoostedByState,
	SetPropertyLoadingState,
	Setintro,
	ShowContactDetailsProperty,
	ShowContactDetailsService,
	UpdateAboutDeveloperProperty,
	UpdateAboutLocationProperty,
	UpdateAboutProperty,
	UpdateAiOverView,
	UpdateAmenities,
	UpdateBusinessAbout,
	UpdateBusinessBrokerage,
	UpdateBusinessExpertise,
	UpdateBusinessFAQs,
	UpdateBusinessHiring,
	UpdateBusinessHonors,
	UpdateBusinessLanguages,
	UpdateBusinessLicences,
	UpdateBusinessProfileAboutDetails,
	UpdateBusinessProfileAreasOfOperation,
	UpdateBusinessProfileEmail,
	UpdateBusinessProfileEstDate,
	UpdateBusinessProfileIndustrytype,
	UpdateBusinessProfileLanguages,
	UpdateBusinessProfileLeadsNotes,
	UpdateBusinessProfileLeadsStatus,
	UpdateBusinessProfileListingsCount,
	UpdateBusinessProfileOfficeAddresses,
	UpdateBusinessProfilePhone,
	UpdateBusinessProfilePicture,
	UpdateBusinessProfileShortDescription,
	UpdateBusinessProfileTitle,
	UpdateBusinessProfileWebsite,
	UpdateBusinessProfilecountry_code,
	UpdateBusinessServices,
	UpdateBusinessWhyChooseUs,
	UpdateConfigAndArea,
	UpdateOverView,
	UpdatePropertyDetails,
	UpdatePropertyTitle,
	UpdateServiceAreasOfOperation,
	UpdateServiceDetails,
	UpdateServiceOverView,
	UpdateServiceProvider,
	UpdateServiceSpecification,
	UpdateServiceTitle,
	UpdateUserAboutDetails,
	UpdateUserDetail,
	UpdateUserProfileLeadsNotes,
	UpdateUserProfileLeadsStatus,
	UpdateUserSkills,
	UpdateVicinity,
	acceptEmployeeRequest,
	addAdminUserDetails,
	addBusinessProfileCoverImage,
	addBusinessProfilePicture,
	addCoverImage,
	addExperienceForUser,
	addProfilePicture,
	addPropertyImages,
	addServiceImages,
	addingbeegrupointsshareproperty,
	bookmark,
	businessDashboardAddAdminUserDetails,
	businessDashboardDeleteAdminUserDetails,
	businessProfileListTabView,
	cancelBoost,
	changeAutoplayVideos,
	changeDataTuning,
	changeDefaultVideoQuality,
	changeMe,
	changeRecievePromotionalEmails,
	commentSwr,
	createNewBusinessProfile,
	createNewOFBProperty,
	createNewOFBPropertyUser,
	createNewProperty,
	createNewPropertyWithUploadTool,
	createNewServiceWithUploadTool,
	cropPropertyImage,
	cropServiceImage,
	deleteAdminUserDetails,
	deleteBusinessCoverImage,
	deleteBusinessProfile,
	deleteBusinessProfilePicture,
	deleteEmployeeRequest,
	deletePostUser,
	deletePropertyDetails,
	deletePropertyImage,
	deletePropertyOFBDetails,
	deletePropertyOFBUsers,
	deleteReview,
	deleteServiceDetails,
	deleteServiceImage,
	deleteUserCoverImage,
	deleteUserProfilePicture,
	fetchAboutSingleProperty,
	fetchAccountSettingsFilesCount,
	fetchAccountSettingsPageData,
	fetchAccountSettingsProfileKycInfo,
	fetchAccountSettingsRewardtransactions,
	fetchAccountSettingsSubscriptions,
	fetchAccountSettingsSubscriptionsPlans,
	fetchAccountSettingsUserBeegruPointstransactions,
	fetchAccountSettingsUserCashtransactions,
	fetchAccountSettingsUserData,
	fetchBoostBusinessProfilePageData,
	fetchBoostPostPageData,
	fetchBoostPropertyPageData,
	fetchBoostServicePageData,
	fetchBoostUserProfilePageData,
	fetchBusinessDashboardAdmins,
	fetchBusinessDashboardBoostsMobile,
	fetchBusinessDashboardLeadsDesktop,
	fetchBusinessDashboardLeadsMobile,
	fetchBusinessDashboardLeadsOverview,
	fetchBusinessDashboardListings,
	fetchBusinessDashboardOFBsOverview,
	fetchBusinessDashboardProfileData,
	fetchBusinessDashboardPropertiesOverview,
	fetchBusinessDashboardReviewsMobile,
	fetchBusinessDashboardSavedItems,
	fetchBusinessDashboardServicesOverview,
	fetchBusinessProfileAboutDetails,
	fetchBusinessProfileAdminsDetailsAndEmployeeRequest,
	fetchBusinessProfileAnalyticsOFBs,
	fetchBusinessProfileAnalyticsPost,
	fetchBusinessProfileAnalyticsProperty,
	fetchBusinessProfileAnalyticsService,
	fetchBusinessProfileBrokerage,
	fetchBusinessProfileExpertise,
	fetchBusinessProfileFaqs,
	fetchBusinessProfileFollowerAndFollowing,
	fetchBusinessProfileGivenReviews,
	fetchBusinessProfileHiring,
	fetchBusinessProfileHonorsAndAwards,
	// fetchHomePageTrendingBusinessProfilesData,
	fetchBusinessProfileImages,
	fetchBusinessProfileLanguages,
	fetchBusinessProfileLeads,
	fetchBusinessProfileLicencesAndCertificates,
	fetchBusinessProfileListingOFB,
	fetchBusinessProfileListingProperty,
	fetchBusinessProfileListingService,
	fetchBusinessProfileNoOfEmployees,
	fetchBusinessProfilePosts,
	fetchBusinessProfilePublishedProperties,
	fetchBusinessProfilePublishedServices,
	fetchBusinessProfileReviews,
	fetchBusinessProfileSavedOFB,
	fetchBusinessProfileSavedPost,
	fetchBusinessProfileSavedProperty,
	fetchBusinessProfileSavedService,
	fetchBusinessProfileServiceOffered,
	fetchBusinessProfileSubscription,
	fetchBusinessProfileUserViewListings,
	fetchBusinessProfileWhychoose,
	fetchDevelopers,
	fetchFaqs,
	fetchGlobalSearchResults,
	fetchHomePageData,
	fetchHomePageFeaturedPropertiesData,
	fetchHomePageHotPropertiesBuyLocalStorageData,
	fetchHomePageHotPropertiesData,
	fetchHomePageHotPropertiesOfbLocalStorageData,
	fetchHomePageHotPropertiesRentLocalStorageData,
	// fetchHomePageTrendingUsersData,
	fetchHomePageTopBusinessProfilesData,
	fetchHomePageTopServicesData,
	// fetchHomePageTrendingServicesData,
	fetchHomePageTopUsersData,
	fetchLeadsById,
	fetchNearbyProperties,
	fetchNeighbourhoodReviewsPageData,
	fetchNotificationPage,
	fetchOnBoardingBusinessTitle,
	fetchOneTimeRewards,
	fetchPropertyCardData,
	fetchPropertyDataReviewsPageData,
	fetchPropertyReviewsPageData,
	fetchPropertyUserDataReviewsPageData,
	fetchRealtimeNotification,
	fetchReceiptPageData,
	fetchRecurringRewards,
	fetchSearchData,
	fetchServiceDataReviewsPageData,
	fetchServiceReviewsPageData,
	fetchServiceUserDataReviewsPageData,
	fetchSingleBusinessProfile,
	fetchSingleFaq,
	fetchSinglePost,
	fetchSingleProperty,
	fetchSinglePropertyAboutLocation,
	fetchSinglePropertyAmenities,
	fetchSinglePropertyConfigAndArea,
	fetchSinglePropertyCreator,
	fetchSinglePropertyDetails,
	fetchSinglePropertyDeveloper,
	fetchSinglePropertyHotProperties,
	fetchSinglePropertyImages,
	fetchSinglePropertyLocationCoordinates,
	fetchSinglePropertyNeighbourhoodReviews,
	fetchSinglePropertyOverview,
	fetchSinglePropertyReviews,
	fetchSinglePropertyTrendingProperties,
	fetchSinglePropertyVicinity,
	fetchSingleServiceAreasOfOperation,
	fetchSingleServiceCreatorDetails,
	fetchSingleServiceDetails,
	fetchSingleServiceImages,
	fetchSingleServiceMapView,
	fetchSingleServiceOverview,
	fetchSingleServicePageData,
	fetchSingleServiceProvider,
	fetchSingleServiceReviews,
	fetchSingleServiceSpecifications,
	fetchSingleServiceTopServices,
	fetchSingleServiceTrendingServices,
	fetchSingleUserAnalytics,
	fetchSingleUserProfile,
	fetchSingleUserProfileAboutDetails,
	fetchSingleUserProfileCoverImageAndProfilePicture,
	fetchSingleUserProfileFollowersAndFollowings,
	fetchSingleUserProfileGivenReviews,
	fetchSingleUserProfileListings,
	fetchSingleUserProfilePosts,
	fetchSingleUserProfileReviews,
	fetchSingleUserSavedItems,
	fetchSingleUserSavedOFB,
	fetchSingleUserSavedPost,
	fetchSingleUserSavedProperties,
	fetchSingleUserSavedService,
	fetchUserProfileLeads,
	followUser,
	loadMoreGlobalSearchPosts,
	loadMoreGlobalSearchResults,
	loadMoreNotificationPage,
	loadMoreOfbProperty,
	loadMoreProperties,
	loadMoreSavedOFB,
	loadMoreSavedPosts,
	loadMoreSavedProperties,
	loadMoreSavedServices,
	loadMoreServices,
	loadMoreUserProperties,
	loadMoreUserSavedOFB,
	loadMoreUserSavedPosts,
	loadMoreUserSavedProperties,
	loadMoreUserSavedServices,
	postSwrForBusiness,
	postSwrForUser,
	profileContext,
	publicproperty,
	publicservice,
	publishproperty,
	publishservice,
	reportPost,
	searchBusinessProfileOfbProperties,
	searchBusinessProfileProperties,
	searchBusinessProfileServices,
	searchOfbPropertyListings,
	searchPropertyListings,
	searchServiceListings,
	searchUserProperties,
	searchUserPropertyListings,
	setAccountSettingSnackbar,
	setActionForbooms,
	setAnalyticsTabValue,
	setBottomBuyBeegruPoints,
	setBusinessDashboardAdminLoading,
	setBusinessDashboardAnalyticsFilterEndDateX,
	setBusinessDashboardAnalyticsFilterGraphDurationX,
	setBusinessDashboardAnalyticsFilterGraphTypeY,
	setBusinessDashboardAnalyticsFilterListingId,
	setBusinessDashboardAnalyticsFilterListingType,
	setBusinessDashboardAnalyticsFilterStartDateX,
	setBusinessDashboardAnalyticsGraphSource,
	setBusinessDashboardAnalyticsLoading,
	setBusinessDashboardBoostsFilterListingType,
	setBusinessDashboardBoostsFilterPageNumber,
	setBusinessDashboardBoostsFilterSortField,
	setBusinessDashboardBoostsFilterSortOrder,
	setBusinessDashboardBoostsFilterStatus,
	setBusinessDashboardBoostsInitialLoadDone,
	setBusinessDashboardBoostsLoading,
	setBusinessDashboardBoostsTriggerNewSearch,
	setBusinessDashboardCurrentScreen,
	setBusinessDashboardLeadsFilterListingType,
	setBusinessDashboardLeadsFilterPageNumber,
	setBusinessDashboardLeadsFilterSortField,
	setBusinessDashboardLeadsFilterSortOrder,
	setBusinessDashboardLeadsFilterStatus,
	setBusinessDashboardLeadsInitialLoadDone,
	setBusinessDashboardLeadsLoading,
	setBusinessDashboardLeadsTriggerNewSearch,
	setBusinessDashboardListingsFilterListingType,
	setBusinessDashboardListingsFilterPageNumber,
	setBusinessDashboardListingsFilterSortField,
	setBusinessDashboardListingsFilterSortOrder,
	setBusinessDashboardListingsFilterStatus,
	setBusinessDashboardListingsFilterTransactionType,
	setBusinessDashboardListingsInitialLoadDone,
	setBusinessDashboardListingsLoading,
	setBusinessDashboardListingsTriggerNewSearch,
	setBusinessDashboardReviewsFilterPageNumber,
	setBusinessDashboardReviewsFilterSortField,
	setBusinessDashboardReviewsFilterSortOrder,
	setBusinessDashboardReviewsInitialLoadDone,
	setBusinessDashboardReviewsTriggerNewSearch,
	setBusinessDashboardSavedItemsFilterListingType,
	setBusinessDashboardSavedItemsFilterPageNumber,
	setBusinessDashboardSavedItemsInitialLoadDone,
	setBusinessDashboardSavedItemsLoading,
	setBusinessDashboardSavedItemsTriggerNewSearch,
	setBusinessDashboardUserViewTabValue,
	setBusinessProfileAboutDesignation,
	setBusinessProfileAboutEducation,
	setBusinessProfileAboutIndustryType,
	setBusinessProfileAboutLongDescription,
	setBusinessProfileAboutOfficeAddress,
	setBusinessProfileAboutOrganization,
	setBusinessProfileAboutShortDescription,
	setBusinessProfileAboutWebsiteUrl,
	setBusinessProfileAboutYearEstablished,
	setBusinessProfileBrokerage,
	setBusinessProfileEmail,
	setBusinessProfileExpertise,
	setBusinessProfileFAQ,
	setBusinessProfileHonorsAndAwards,
	setBusinessProfileLanguages,
	setBusinessProfileLicencesAndCertificates,
	setBusinessProfileNoOfEmployees,
	setBusinessProfilePhoneCountryCode,
	setBusinessProfilePhoneNumber,
	setBusinessProfileServicesOfferedTags,
	setBusinessProfileSnackbar,
	setBusinessProfileVacancies,
	setBusinessProfileWhyChooseUs,
	setCreateBoost,
	setCreateNePropertyMinBalconies,
	setCreateNePropertyMinSuperBuiltUpArea,
	setCreateNewPropertyAge,
	setCreateNewPropertyAvailabilty,
	setCreateNewPropertyCategoryType,
	setCreateNewPropertyCurrentPage,
	setCreateNewPropertyDefaultLocation,
	setCreateNewPropertyFloorNo,
	setCreateNewPropertyFurnishing,
	setCreateNewPropertyLandArea,
	setCreateNewPropertyLandAreaUnit,
	setCreateNewPropertyLandFacing,
	setCreateNewPropertyMaxBalconies,
	setCreateNewPropertyMaxBathrooms,
	setCreateNewPropertyMaxBedrooms,
	setCreateNewPropertyMaxCarpetArea,
	setCreateNewPropertyMaxFloors,
	setCreateNewPropertyMaxPrice,
	setCreateNewPropertyMaxSuperBuiltUpArea,
	setCreateNewPropertyMinBathrooms,
	setCreateNewPropertyMinBedrooms,
	setCreateNewPropertyMinCarpetArea,
	setCreateNewPropertyMinPrice,
	setCreateNewPropertyNoOfSeats,
	setCreateNewPropertyNoOfUnits,
	setCreateNewPropertyOverview,
	setCreateNewPropertyPlotDimensions,
	setCreateNewPropertySecurityDeposit,
	setCreateNewPropertySubType,
	setCreateNewPropertySuperBuiltUpAreaUnit,
	setCreateNewPropertyTransactionType,
	setCreateNewPropertyType,
	setCreateNewPropertyUnitFacing,
	setCreateNewPropertyUploadImages,
	setCreateNewServiceCategory,
	setCreateNewServiceCurrentPage,
	setCreateNewServiceDefaultLocation,
	setCreateNewServiceMaxPrice,
	setCreateNewServiceMinPrice,
	setCreateNewServiceOtherSubType,
	setCreateNewServiceProviderName,
	setCreateNewServiceShowPrice,
	setCreateNewServiceSubType,
	setCreateNewServiceType,
	setCreateNewServiceUploadImages,
	setCreateNewePropertyPossessionDate,
	setCreatePropertySelectedImagesUrl,
	setDataBeforeEdit,
	setDefaultProfileContext,
	setDrawerScreen,
	setFaqSearchResults,
	setHomeLoading,
	setHomeLocation,
	setHomePageBusinessProfileLoading,
	setHomePagePropertiesLoading,
	setImageCategory,
	setImagesIndex,
	setIsLoading,
	setIsSearching,
	setLanguage,
	setListingTabValue,
	setLoading,
	setOnBoardingDetails,
	setPostPropertyWithUploadToolSnackbarError,
	setProfileContext,
	setPropertyImagesUploadCompleted,
	setPropertySnackbar,
	setSaveTabValue,
	setSavedIsPublic,
	setSearchAnimationDetails,
	setSearchFilterMetrices,
	setSearchOFBProperties,
	setSearchProperties,
	setSearchResultsPageNumber,
	setSearchServices,
	setSectionToEdit,
	setServiceImagesUploadCompleted,
	setServiceSnackbar,
	setSinglePropertyAbout,
	setSinglePropertyAboutLocation,
	setSinglePropertyAge,
	setSinglePropertyAmenities,
	setSinglePropertyAvailabilty,
	setSinglePropertyCategoryType,
	setSinglePropertyDescription,
	setSinglePropertyDeveloperName,
	setSinglePropertyEstablishedYear,
	setSinglePropertyFloorNo,
	setSinglePropertyFurnishing,
	setSinglePropertyLandArea,
	setSinglePropertyLandFacing,
	setSinglePropertyManualOverview,
	setSinglePropertyMaxBalconies,
	setSinglePropertyMaxBathrooms,
	setSinglePropertyMaxBedrooms,
	setSinglePropertyMaxCarpetArea,
	setSinglePropertyMaxFloors,
	setSinglePropertyMaxPrice,
	setSinglePropertyMaxSuperBuiltUpArea,
	setSinglePropertyMinBalconies,
	setSinglePropertyMinBathrooms,
	setSinglePropertyMinBedrooms,
	setSinglePropertyMinCarpetArea,
	setSinglePropertyMinPrice,
	setSinglePropertyMinSuperBuiltUpArea,
	setSinglePropertyNoOfSeats,
	setSinglePropertyNoOfUnits,
	setSinglePropertyOtherPlotDimensions,
	setSinglePropertyOverview,
	setSinglePropertyPlotDimensions,
	setSinglePropertyPossessionDate,
	setSinglePropertySecurityDeposit,
	setSinglePropertySubType,
	setSinglePropertyTags,
	setSinglePropertyTransactionType,
	setSinglePropertyType,
	setSinglePropertyUnitFacing,
	setSinglePropertyVicinity,
	setSingleServiceAreasOfOperation,
	setSingleServiceCategory,
	setSingleServiceLocationCoordinates,
	setSingleServiceLocationPincode,
	setSingleServiceLocationTitle,
	setSingleServiceMaxPrice,
	setSingleServiceMinPrice,
	setSingleServiceOtherSubtype,
	setSingleServiceOverview,
	setSingleServiceProviderDescription,
	setSingleServiceProviderEstablishedYear,
	setSingleServiceProviderName,
	setSingleServiceShowPrice,
	setSingleServiceSpecification,
	setSingleServiceSubtype,
	setSingleServiceTags,
	setSingleServiceType,
	setStartBoost,
	setTabValue,
	setThemeReducer,
	setTransactionType,
	setUserLoading,
	setUserLocation,
	setUserProfileSnackbar,
	setUserSavedItemsFilterListingType,
	setUserSavedItemsFilterPageNumber,
	setUserSavedItemsInitialLoadDone,
	setUserSavedItemsLoading,
	setUserSavedItemsTriggerNewSearch,
	storeleads,
	switchBusinessDashboardContantDetails,
	unfollowUser,
	updateBeegrupointsAfterPurchase,
	updateBlasts,
	updateBooms,
	updateBoost,
	updateBusinessProfileKycInfo,
	updateLocationProperty,
	updateNofEmployees,
	updateNotificationViewed,
	updatePersonalInfo,
	updatePropertyImage,
	updateReduxSession,
	updateSearchFilterActiveOptions,
	updateSearchFilterApplied,
	updateSearchFilterIsAccordionOpen,
	updateSearchFilterSelectedOptions,
	updateSearchFilterVisibility,
	updateServiceImage,
	updateServiceLocation,
	updateUserKycInfo,
	updatedPublishedProperties,
	updatedPublishedServices,
} from "./reducers";

import {
	account_settings_page,
	business_dashboard,
	new_property_data,
	new_service_data,
	notifications_page,
	on_boarding_title,
	profile_context,
	property_reviews_page,
	redux_session,
	saved_posts_list,
	search_filter_metrices,
	service_reviews_page,
	single_business_profile,
	single_property_page,
	single_service_page,
	single_user_profile,
} from "./sub-state-data-empty/sub-state-data-empty";

import { generateInitialSearchFiltersReduxState } from "../lib/data/search-filters/helpers";
import {
	account_setting_loading,
	accountsetting_snackbar,
	boost,
	business_profile_loading,
	business_profile_snackbar,
	home_loading,
	looking_for,
	notifications_loading,
	property_loading,
	property_snackbar,
	service_loading,
	service_snackbar,
	user_profile_loading,
	user_profile_snackbar,
} from "./sub-state-data/sub-state-data";

/*

& Now we'll describe a global slice for our global state. Using the createSlice() function, we avoid the all-so-common mistake of trying to mutate the immutable redux store directly.

* Reducers are pure functions that take the current state and an action, and return a new state. But since createSlice() uses the Immer library which tracks changes on a draft state before returning a new state, we can directly mutate the state in the reducer.

* It is possible to create various slices for different parts of the state, but we'll skip over this detail for now as it will add a lot of time to the development cycle.

^ All reducers for this app are described in the redux-magic/reducers.ts file.

*/

export const globalSlice = createSlice({
	/*

    * Name of the slice. This is the key used to access the slice in the store.

    */

	name: "globalState",

	/*

    * Initial state of the slice. This is the state that will be used before any other redux magic happens.
    ! Changed the initial state to an empty object to ensure app is not data heavy during hydration.
    ! This may cause some code to break. Please call the appropriate thunk in getServerSideProps() to hydrate the state.

  */

	// initialState: defaultStateData as StateInterface,
	initialState: {
		homePage: {},
		single_user_profile: single_user_profile,
		single_business_profile: single_business_profile,
		property_reviews_page: property_reviews_page,
		single_service_page: single_service_page,
		account_settings_page: account_settings_page,
		single_property_page: single_property_page,
		service_reviews_page: service_reviews_page,
		saved_posts_list: saved_posts_list,
		home_loading: home_loading,
		property_loading: property_loading,
		property_snackbar: property_snackbar,
		service_snackbar: service_snackbar,
		business_profile_snackbar: business_profile_snackbar,
		service_loading: service_loading,
		business_profile_loading: business_profile_loading,
		looking_for: looking_for,
		user_profile_loading: user_profile_loading,
		notifications_page: notifications_page,
		notifications_loading: notifications_loading,
		account_setting_loading: account_setting_loading,
		accountsetting_snackbar: accountsetting_snackbar,
		on_boarding_title: on_boarding_title,
		user_profile_snackbar: user_profile_snackbar,
		redux_session: redux_session,
		profile_context: profile_context,
		boost: boost,
		search_filter_metrices: search_filter_metrices,
		new_property_data: new_property_data,
		new_service_data: new_service_data,
		business_dashboard: business_dashboard,
		/*

		! Please do not push test version of state generator function to production!

		*/
		search_filters_state: generateInitialSearchFiltersReduxState(),
	},

	/*

    * Reducers. These are the functions that will be used to update the state.

    ^ All reducers for this app are described in the redux-magic/reducers.ts file.

    */

	reducers: {
		changeMe,
		setThemeReducer,
		fetchSingleProperty,
		CreateNewPost,
		setImageCategory,
		setLanguage,
		setLoading,
		setImagesIndex,
		fetchSingleBusinessProfile,
		fetchGlobalSearchResults,
		loadMoreGlobalSearchResults,
		loadMoreGlobalSearchPosts,
		fetchPropertyReviewsPageData,
		fetchSingleUserProfile,
		fetchBoostPostPageData,
		fetchBoostBusinessProfilePageData,
		fetchBoostUserProfilePageData,
		fetchBoostPropertyPageData,
		fetchSingleServicePageData,
		fetchSingleServiceImages,
		fetchSingleServiceDetails,
		fetchSingleServiceOverview,
		fetchSingleServiceSpecifications,
		fetchSingleServiceProvider,
		fetchSingleServiceAreasOfOperation,
		fetchSingleServiceMapView,
		fetchSingleServiceReviews,
		fetchSingleServiceCreatorDetails,
		fetchSingleServiceTopServices,
		fetchSingleServiceTrendingServices,
		setSingleServiceCategory,
		setSingleServiceType,
		setSingleServiceSubtype,
		setSingleServiceOtherSubtype,
		setSingleServiceTags,
		setSingleServiceShowPrice,
		setSingleServiceMinPrice,
		setSingleServiceMaxPrice,
		setSingleServiceOverview,
		setSingleServiceSpecification,
		setSingleServiceProviderName,
		setSingleServiceProviderEstablishedYear,
		setSingleServiceProviderDescription,
		setSingleServiceAreasOfOperation,
		setSingleServiceLocationTitle,
		setSingleServiceLocationPincode,
		setSingleServiceLocationCoordinates,
		setSectionToEdit,
		setDataBeforeEdit,
		fetchNeighbourhoodReviewsPageData,
		fetchServiceReviewsPageData,
		fetchBoostServicePageData,
		fetchHomePageData,
		fetchNotificationPage,
		loadMoreNotificationPage,
		fetchRealtimeNotification,
		updateNotificationViewed,
		setTabValue,
		setSaveTabValue,
		setListingTabValue,
		setUserLocation,
		addAdminUserDetails,
		deleteAdminUserDetails,
		UpdateBusinessAbout,
		UpdateBusinessBrokerage,
		UpdateBusinessExpertise,
		UpdateBusinessLanguages,
		UpdateBusinessServices,
		UpdateBusinessWhyChooseUs,
		UpdateBusinessFAQs,
		UpdateBusinessHiring,
		AddSepcialOfferDetailsForBusinessProfile,
		UpdateBusinessHonors,
		UpdateBusinessLicences,
		fetchFaqs,
		setFaqSearchResults,
		fetchSingleFaq,
		FaqAddUpvote,
		FaqAddDownvote,
		FaqRemoveUpvote,
		FaqRemoveDownvote,
		SetDefaultLookingFor,
		SetDefaultLocationBoost,
		SetDefaultLocation,
		UpdateUserAboutDetails,
		UpdateUserSkills,
		addExperienceForUser,
		AddUserCourses,
		AddUserLanguage,
		AddingBeegruPointsForShareService,
		AddHonorsAndAwardsDetailsForUser,
		AddLicenseAndCertificationDetailsForUser,
		AddSaveEntityBusinessProfile,
		AddSaveEntityUserProfile,
		UpdateServiceAreasOfOperation,
		UpdateServiceOverView,
		UpdateServiceDetails,
		UpdateServiceSpecification,
		UpdateServiceProvider,
		followUser,
		unfollowUser,
		UpdatePropertyDetails,
		UpdateConfigAndArea,
		UpdateAboutDeveloperProperty,
		UpdateAboutLocationProperty,
		UpdateAboutProperty,
		UpdateAmenities,
		UpdateOverView,
		UpdateVicinity,
		setDrawerScreen,
		setCreateBoost,
		setStartBoost,
		EmployeeRequest,
		acceptEmployeeRequest,
		deleteEmployeeRequest,
		updateBoost,
		cancelBoost,
		fetchReceiptPageData,
		fetchAccountSettingsPageData,
		fetchAccountSettingsProfileKycInfo,
		addPropertyImages,
		createNewProperty,
		CreateNewService,
		Setintro,
		KycProfileTypes,
		updatePropertyImage,
		cropPropertyImage,
		deletePropertyImage,
		deleteServiceImage,
		CreateNewSubscription,
		RenewSubscription,
		CancelSubscription,
		ProfileToUpgrade,
		ProfileToUpgradeId,
		ProfileToUpgradeType,
		deletePropertyDetails,
		updateUserKycInfo,
		updateBusinessProfileKycInfo,
		addServiceImages,
		updateServiceImage,
		cropServiceImage,
		updatePersonalInfo,
		updateLocationProperty,
		updateServiceLocation,
		addProfilePicture,
		createNewBusinessProfile,
		deleteBusinessProfile,
		createNewOFBProperty,
		deletePropertyOFBDetails,
		createNewOFBPropertyUser,
		CreateNewComments,
		deletePropertyOFBUsers,
		addCoverImage,
		updateBooms,
		RemoveBooms,
		updateBlasts,
		addBusinessProfilePicture,
		addBusinessProfileCoverImage,
		CreateNewPostUser,
		CreateNewCommentsUserPost,
		deletePostUser,
		ReplyToComment,
		deleteServiceDetails,
		setProfileContext,
		setActionForbooms,
		profileContext,
		bookmark,
		reportPost,
		postSwrForBusiness,
		postSwrForUser,
		commentSwr,
		setIsSearching,
		setSearchResultsPageNumber,
		setIsLoading,
		publicproperty,
		publishproperty,
		publicservice,
		publishservice,
		OnBoarding,
		updateNofEmployees,
		setSavedIsPublic,
		fetchSinglePost,
		storeleads,
		businessProfileListTabView,
		deleteBusinessProfilePicture,
		deleteBusinessCoverImage,
		deleteUserProfilePicture,
		deleteUserCoverImage,
		setSearchProperties,
		setSearchServices,
		setSearchOFBProperties,
		CreateNewReview,
		loadMoreProperties,
		loadMoreServices,
		loadMoreOfbProperty,
		loadMoreSavedProperties,
		loadMoreSavedOFB,
		loadMoreUserSavedProperties,
		loadMoreUserSavedServices,
		loadMoreUserSavedPosts,
		loadMoreUserSavedOFB,
		loadMoreSavedServices,
		loadMoreSavedPosts,
		loadMoreUserProperties,
		searchPropertyListings,
		searchUserPropertyListings,
		changeRecievePromotionalEmails,
		changeAutoplayVideos,
		changeDefaultVideoQuality,
		CreateNewBugReport,
		CreateNewReport,
		UpdateBusinessProfileShortDescription,
		UpdateBusinessProfilecountry_code,
		UpdateBusinessProfilePhone,
		UpdateBusinessProfileEmail,
		UpdateBusinessProfileEstDate,
		UpdateBusinessProfileWebsite,
		UpdateBusinessProfileOfficeAddresses,
		UpdateBusinessProfileIndustrytype,
		setHomeLocation,
		setHomeLoading,
		fetchHomePageHotPropertiesData,
		fetchHomePageFeaturedPropertiesData,
		fetchHomePageTopServicesData,
		// fetchHomePageTrendingServicesData,
		fetchHomePageTopUsersData,
		// fetchHomePageTrendingUsersData,
		fetchHomePageTopBusinessProfilesData,
		// fetchHomePageTrendingBusinessProfilesData,
		fetchSingleUserProfileCoverImageAndProfilePicture,
		fetchSingleUserProfileFollowersAndFollowings,
		fetchSingleUserProfileAboutDetails,
		fetchSingleUserProfileListings,
		fetchSingleUserProfileReviews,
		fetchSingleUserSavedProperties,
		fetchSingleUserAnalytics,
		fetchSingleUserSavedPost,
		fetchSingleUserSavedService,
		fetchSingleUserSavedOFB,
		fetchBusinessProfileImages,
		fetchBusinessDashboardProfileData,
		fetchBusinessProfileAboutDetails,
		fetchBusinessProfileFollowerAndFollowing,
		fetchBusinessProfileServiceOffered,
		fetchBusinessProfileExpertise,
		fetchBusinessProfileBrokerage,
		fetchBusinessProfileWhychoose,
		fetchBusinessProfileHiring,
		fetchBusinessProfileHonorsAndAwards,
		fetchBusinessProfileFaqs,
		fetchBusinessProfileLanguages,
		fetchBusinessProfileLicencesAndCertificates,
		fetchBusinessProfileNoOfEmployees,
		fetchBusinessProfileListingProperty,
		fetchBusinessProfileReviews,
		setAnalyticsTabValue,
		fetchBusinessProfileListingService,
		fetchBusinessProfileListingOFB,
		fetchBusinessProfileLeads,
		fetchBusinessProfileSavedProperty,
		fetchBusinessProfileSavedService,
		fetchBusinessProfileSavedOFB,
		fetchBusinessProfileSavedPost,
		fetchBusinessProfileAnalyticsProperty,
		fetchBusinessProfileAnalyticsService,
		fetchBusinessProfileAnalyticsPost,
		fetchBusinessProfileAnalyticsOFBs,
		fetchBusinessProfileAdminsDetailsAndEmployeeRequest,
		fetchPropertyDataReviewsPageData,
		fetchPropertyUserDataReviewsPageData,
		fetchServiceDataReviewsPageData,
		fetchServiceUserDataReviewsPageData,
		fetchAccountSettingsUserData,
		fetchAccountSettingsUserCashtransactions,
		fetchAccountSettingsUserBeegruPointstransactions,
		fetchAccountSettingsSubscriptionsPlans,
		fetchAccountSettingsSubscriptions,
		fetchAccountSettingsFilesCount,
		fetchSinglePropertyDetails,
		fetchDevelopers,
		fetchNearbyProperties,
		fetchSinglePropertyConfigAndArea,
		setSinglePropertyTransactionType,
		setSinglePropertyCategoryType,
		setSinglePropertyType,
		setSinglePropertyMinPrice,
		setSinglePropertyMaxPrice,
		setSinglePropertySecurityDeposit,
		setSinglePropertyAvailabilty,
		setSinglePropertyPossessionDate,
		setSinglePropertyTags,
		setSinglePropertyUnitFacing,
		setSinglePropertyLandFacing,
		setSinglePropertyAge,
		setSinglePropertyFurnishing,
		setSinglePropertyPlotDimensions,
		setSinglePropertyOtherPlotDimensions,
		setSinglePropertyLandArea,
		setSinglePropertyMinSuperBuiltUpArea,
		setSinglePropertyMaxSuperBuiltUpArea,
		setSinglePropertyMinCarpetArea,
		setSinglePropertyMaxCarpetArea,
		setSinglePropertyMinBedrooms,
		setSinglePropertyMaxBedrooms,
		setSinglePropertyMinBathrooms,
		setSinglePropertyMaxBathrooms,
		setSinglePropertyMinBalconies,
		setSinglePropertyMaxBalconies,
		setSinglePropertyMaxFloors,
		setSinglePropertyFloorNo,
		setSinglePropertyNoOfUnits,
		setSinglePropertyNoOfSeats,
		setSinglePropertySubType,
		setSinglePropertyAbout,
		setSinglePropertyDeveloperName,
		setSinglePropertyEstablishedYear,
		setSinglePropertyAboutLocation,
		setSinglePropertyDescription,
		setSinglePropertyVicinity,
		fetchAboutSingleProperty,
		fetchSinglePropertyCreator,
		fetchSinglePropertyAboutLocation,
		fetchSinglePropertyAmenities,
		setSinglePropertyAmenities,
		fetchSinglePropertyDeveloper,
		fetchSinglePropertyImages,
		fetchSinglePropertyLocationCoordinates,
		fetchSinglePropertyOverview,
		setSinglePropertyOverview,
		fetchSinglePropertyReviews,
		fetchSinglePropertyVicinity,
		fetchSinglePropertyTrendingProperties,
		fetchSinglePropertyHotProperties,
		fetchSinglePropertyNeighbourhoodReviews,
		setBusinessProfileAboutShortDescription,
		setBusinessProfileAboutLongDescription,
		setBusinessProfileServicesOfferedTags,
		setBusinessProfileExpertise,
		setBusinessProfileWhyChooseUs,
		setBusinessProfileBrokerage,
		setBusinessProfileVacancies,
		setBusinessProfileHonorsAndAwards,
		setBusinessProfileLicencesAndCertificates,
		setBusinessProfileNoOfEmployees,
		setBusinessProfileFAQ,
		setBusinessProfileLanguages,
		setBusinessProfileAboutYearEstablished,
		setBusinessProfileAboutOfficeAddress,
		setBusinessProfileAboutWebsiteUrl,
		setBusinessProfileAboutEducation,
		setBusinessProfileAboutOrganization,
		setBusinessProfileAboutDesignation,
		setBusinessProfilePhoneCountryCode,
		setBusinessProfilePhoneNumber,
		setBusinessProfileEmail,
		setBusinessProfileAboutIndustryType,
		deleteReview,
		AllowcopyProperty,
		ShowContactDetailsProperty,
		ShowContactDetailsService,
		addingbeegrupointsshareproperty,
		fetchSingleUserProfilePosts,
		LoadingMoreUserProfilePosts,
		fetchBusinessProfilePosts,
		LoadingMoreBusinessProfilePosts,
		setHomePageBusinessProfileLoading,
		searchUserProperties,
		setPropertySnackbar,
		setServiceSnackbar,
		setHomePagePropertiesLoading,
		searchBusinessProfileProperties,
		searchBusinessProfileOfbProperties,
		searchOfbPropertyListings,
		searchBusinessProfileServices,
		searchServiceListings,
		setBusinessProfileSnackbar,
		setUserLoading,
		fetchSingleUserProfileGivenReviews,
		LoadingMoreUserGivenReviews,
		LoadingMoreUserReviews,
		fetchBusinessProfileGivenReviews,
		LoadingMoreBusinessProfileGivenReviews,
		LoadingMoreBusinessProfileReviews,
		fetchBusinessProfileSubscription,
		setAccountSettingSnackbar,
		setTransactionType,
		setOnBoardingDetails,
		fetchOnBoardingBusinessTitle,
		setUserProfileSnackbar,
		fetchUserProfileLeads,
		NewEmployeeRquestOnBoarding,
		setBottomBuyBeegruPoints,
		setDefaultProfileContext,
		updateReduxSession,
		FetchMoreBusinessProfiles,
		fetchBusinessProfilePublishedProperties,
		updatedPublishedProperties,
		fetchBusinessProfilePublishedServices,
		updatedPublishedServices,
		fetchHomePageHotPropertiesBuyLocalStorageData,
		fetchHomePageHotPropertiesRentLocalStorageData,
		fetchHomePageHotPropertiesOfbLocalStorageData,
		fetchAccountSettingsRewardtransactions,
		fetchOneTimeRewards,
		fetchRecurringRewards,
		updateSearchFilterActiveOptions,
		updateSearchFilterSelectedOptions,
		updateSearchFilterVisibility,
		updateSearchFilterApplied,
		updateSearchFilterIsAccordionOpen,
		setSearchFilterMetrices,
		CreateNewView,
		setSearchAnimationDetails,
		fetchSearchData,
		fetchPropertyCardData,
		setCreateNewPropertyTransactionType,
		setCreateNewPropertyCurrentPage,
		setCreateNewPropertyCategoryType,
		setCreateNewPropertyType,
		setCreateNewPropertySubType,
		setCreateNewPropertyMinPrice,
		setCreateNewPropertyMaxPrice,
		setCreateNewPropertySecurityDeposit,
		setCreateNewPropertyAvailabilty,
		setCreateNewePropertyPossessionDate,
		setCreateNewPropertyUnitFacing,
		setCreateNewPropertyLandFacing,
		setCreateNewPropertyAge,
		setCreateNewPropertyPlotDimensions,
		setCreateNewPropertyOverview,
		setCreateNewPropertyFurnishing,
		setCreateNewPropertyLandArea,
		setCreateNewPropertyMaxBalconies,
		setCreateNewPropertyMaxBathrooms,
		setCreateNewPropertyMaxBedrooms,
		setCreateNewPropertyMaxCarpetArea,
		setCreateNewPropertyMaxFloors,
		setCreateNewPropertyMaxSuperBuiltUpArea,
		setCreateNePropertyMinBalconies,
		setCreateNewPropertyMinBathrooms,
		setCreateNewPropertyMinBedrooms,
		setCreateNewPropertyMinCarpetArea,
		setCreateNePropertyMinSuperBuiltUpArea,
		setCreateNewPropertyNoOfUnits,
		setCreateNewPropertyNoOfSeats,
		setCreateNewPropertyFloorNo,
		setCreateNewPropertyDefaultLocation,
		setCreateNewPropertyUploadImages,
		setPropertyImagesUploadCompleted,
		setServiceImagesUploadCompleted,
		setCreatePropertySelectedImagesUrl,
		setCreateNewPropertyLandAreaUnit,
		setCreateNewPropertySuperBuiltUpAreaUnit,
		UpdateAiOverView,
		createNewPropertyWithUploadTool,
		setPostPropertyWithUploadToolSnackbarError,
		setCreateNewServiceType,
		setCreateNewServiceSubType,
		setCreateNewServiceOtherSubType,
		setCreateNewServiceCategory,
		setCreateNewServiceDefaultLocation,
		setCreateNewServiceUploadImages,
		setCreateNewServiceMinPrice,
		setCreateNewServiceMaxPrice,
		setCreateNewServiceShowPrice,
		setCreateNewServiceProviderName,
		changeDataTuning,
		createNewServiceWithUploadTool,
		setCreateNewServiceCurrentPage,
		UpdatePropertyTitle,
		// PropertyBoost
		SetPropertyBoostMobileStepState,
		SetPropertyBoostSnackState,
		SetPropertyBoostModalState,
		SetPropertyBoostedByState,
		SetPropertyBoostDataState,
		SetPropertyBoostDetailState,
		SetPropertyBoostLocationState,
		SetPropertyBoostPointsState,
		SetPropertyLoadingState,
		CreatePropertyBoostState,
		CancelPropertyBoostState,
		UpdateBusinessProfileLeadsStatus,
		UpdateBusinessProfileLeadsNotes,
		UpdateUserProfileLeadsNotes,
		UpdateUserProfileLeadsStatus,
		UpdateServiceTitle,
		setSinglePropertyManualOverview,
		updateBeegrupointsAfterPurchase,
		fetchBusinessDashboardLeadsDesktop,
		setBusinessDashboardCurrentScreen,
		setBusinessDashboardLeadsTriggerNewSearch,
		setBusinessDashboardLeadsInitialLoadDone,
		fetchLeadsById,
		fetchBusinessDashboardLeadsMobile,
		fetchBusinessDashboardSavedItems,
		setBusinessDashboardSavedItemsFilterListingType,
		setBusinessDashboardSavedItemsFilterPageNumber,
		setBusinessDashboardSavedItemsInitialLoadDone,
		setBusinessDashboardSavedItemsLoading,
		setBusinessDashboardSavedItemsTriggerNewSearch,
		setBusinessDashboardLeadsFilterStatus,
		setBusinessDashboardLeadsFilterListingType,
		setBusinessDashboardLeadsFilterSortField,
		setBusinessDashboardLeadsFilterSortOrder,
		setBusinessDashboardLeadsFilterPageNumber,
		setBusinessDashboardReviewsInitialLoadDone,
		setBusinessDashboardReviewsTriggerNewSearch,
		setBusinessDashboardReviewsFilterPageNumber,
		fetchBusinessDashboardReviewsMobile,
		setBusinessDashboardReviewsFilterSortField,
		setBusinessDashboardReviewsFilterSortOrder,
		setBusinessDashboardLeadsLoading,
		setBusinessDashboardListingsTriggerNewSearch,
		setBusinessDashboardListingsInitialLoadDone,
		setBusinessDashboardListingsLoading,
		setBusinessDashboardListingsFilterStatus,
		setBusinessDashboardListingsFilterListingType,
		setBusinessDashboardListingsFilterTransactionType,
		setBusinessDashboardListingsFilterSortField,
		setBusinessDashboardListingsFilterSortOrder,
		setBusinessDashboardListingsFilterPageNumber,
		fetchBusinessDashboardListings,
		setBusinessDashboardAnalyticsLoading,
		setBusinessDashboardAnalyticsGraphSource,
		setBusinessDashboardAnalyticsFilterListingType,
		setBusinessDashboardAnalyticsFilterListingId,
		setBusinessDashboardAnalyticsFilterGraphTypeY,
		setBusinessDashboardAnalyticsFilterGraphDurationX,
		setBusinessDashboardAnalyticsFilterStartDateX,
		setBusinessDashboardAnalyticsFilterEndDateX,
		setBusinessDashboardUserViewTabValue,
		fetchBusinessProfileUserViewListings,
		businessDashboardAddAdminUserDetails,
		fetchBusinessDashboardAdmins,
		businessDashboardDeleteAdminUserDetails,
		setBusinessDashboardAdminLoading,
		fetchBusinessDashboardLeadsOverview,
		fetchBusinessDashboardPropertiesOverview,
		fetchBusinessDashboardOFBsOverview,
		fetchBusinessDashboardServicesOverview,
		fetchBusinessDashboardBoostsMobile,
		setBusinessDashboardBoostsFilterListingType,
		setBusinessDashboardBoostsFilterPageNumber,
		setBusinessDashboardBoostsFilterSortField,
		setBusinessDashboardBoostsFilterSortOrder,
		setBusinessDashboardBoostsFilterStatus,
		setBusinessDashboardBoostsInitialLoadDone,
		setBusinessDashboardBoostsLoading,
		setBusinessDashboardBoostsTriggerNewSearch,
		UpdateBusinessProfileTitle,
		UpdateBusinessProfilePicture,
		UpdateBusinessProfileLanguages,
		UpdateBusinessProfileAboutDetails,
		UpdateBusinessProfileAreasOfOperation,
		UpdateBusinessProfileListingsCount,
		FetchBusinessDashboardAnalyticsLeadsThisWeek,
		FetchBusinessDashboardAnalyticsMostLeadsGeneratingListing,
		FetchBusinessDashboardAnalyticsListingViewsThisWeek,
		FetchBusinessDashboardAnalyticsMostSavedListing,
		FetchBusinessDashboardAnalyticsProfileViews,
		FetchBusinessDashboardAnalyticsMostViewedListing,
		FetchBusinessDashboardAnalyticsGraph,
		FetchBusinessDashboardAnalyticsGraphCustomDates,
		CreateNewShares,
		fetchSingleUserSavedItems,
		setUserSavedItemsTriggerNewSearch,
		setUserSavedItemsInitialLoadDone,
		setUserSavedItemsLoading,
		setUserSavedItemsFilterListingType,
		setUserSavedItemsFilterPageNumber,
		UpdateUserDetail,
		switchBusinessDashboardContantDetails,
	},

	/*

    * Extra Reducers. These are reducers that are required by next-redux-wrapper and allow us to use redux on the server.

   * UPDATE: We have to use the builder callback notation instead of the object notation for extraReducers as the object notation is now deprecated and will be removed in RTK 2.0.

    */

	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state: any, action: any) => {
			return {
				...state,
				...action.payload.globalState,
			};
		});
	},
});

const makeStore = () =>
	configureStore({
		reducer: {
			[globalSlice.name]: globalSlice.reducer,
		},

		/*

    TODO Remove all debug flags before going live.

    */

		devTools: true,
	});

/*

& Next, we'll describe and export the types for the AppStore, AppState and AppThunk. This is a good practice.

*/

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

/*

~ What is a thunk?

* Arguably, a 'thunk' is the sound your head makes when you hear first hear about "redux-thunk". Jokes aside, it is a function that returns a function. In redux, when you dispatch an action, a thunk allows you to run your reducer logic asynchronously.

* The term 'thunk' originated as a whimsical irregular form of the verb think. It refers to the original use of thunks in ALGOL 60 compilers, which required special analysis (thought) to determine what type of routine to generate.

* When you synchronously dispatch an action that is actually going to run asynchronous code, you're expecting the action to give you data that doesn't yet exist (the reducer has to think about it first). So 'thunk' is just a geeky way of describing a function that returns a function that returns data that the reducer will have already 'thunk' about before it is called.

& Now that you know what a thunk is (I hope), let us export the thunks for our app. To keep this file clean, we're going to describe our thunks in a separate file, export them, import them and then export them yet another time.

^ All thunks for this app are described in the redux-magic/thunks.ts file.

* As a rule of thumb, we're going to describe one thunk for every reducer that we describe.

*/
export {
	AddExperienceForUserThunk,
	AddHonorsAndAwardsDetailsForUserThunk,
	AddLicenseAndCertificationDetailsForUserThunk,
	AddSepcialOfferDetailsForBusinessProfileThunk,
	AddUserCoursesThunk,
	AddUserLanguageThunk,
	AddingBeegruPointsForSharePropertyThunk,
	AddingBeegruPointsForShareServiceThunk,
	AllowcopyPropertyThunk,
	CancelPropertyBoostStateThunk,
	CancelSubscriptionThunk,
	CreateNewCommentsThunk,
	CreateNewCommentsUserPostThunk,
	CreateNewPostThunk,
	CreateNewPostUserThunk,
	CreateNewPropertyThunk,
	CreateNewReviewThunk,
	CreateNewServiceThunk,
	CreateNewServiceWithUploadToolThunk,
	CreateNewSharesThunk,
	CreateNewSubscriptionThunk,
	CreateNewViewThunk,
	CreatePropertyBoostStateThunk,
	CreateWebsiteReportThunk,
	CropPropertyImageThunk,
	CropServiceImageThunk,
	EmployeeRequestThunk,
	FaqAddDownvoteThunk,
	FaqAddUpvoteThunk,
	FaqRemoveDownvoteThunk,
	FaqRemoveUpvoteThunk,
	FetchBusinessDashboardAnalyticsGraphCustomDatesThunk,
	FetchBusinessDashboardAnalyticsGraphThunk,
	FetchBusinessDashboardAnalyticsLeadsThisWeekThunk,
	FetchBusinessDashboardAnalyticsListingViewsThisWeekThunk,
	FetchBusinessDashboardAnalyticsMostLeadsGeneratingListingThunk,
	FetchBusinessDashboardAnalyticsMostSavedListingThunk,
	FetchBusinessDashboardAnalyticsMostViewedListingThunk,
	FetchBusinessDashboardAnalyticsProfileViewsThunk,
	FetchMoreBusinessProfilesThunk,
	KycProfileTypesThunk,
	LoadingMoreBusinessProfileGivenReviewsThunk,
	LoadingMoreBusinessProfilePostsThunk,
	LoadingMoreBusinessProfileReviewsThunk,
	LoadingMoreUserGivenReviewsThunk,
	LoadingMoreUserProfilePostsThunk,
	LoadingMoreUserReviewsThunk,
	NewEmployeeRquestOnBoardingThunk,
	OnBoardingThunk,
	ProfileToUpgradeIdThunk,
	ProfileToUpgradeThunk,
	ProfileToUpgradeTypeThunk,
	PublicPrivatePropertyThunk,
	PublicPrivateServiceThunk,
	PublishPropertyThunk,
	PublishServiceThunk,
	RemoveBoomsThunk,
	RenewSubscriptionThunk,
	ReplyToCommentThunk,
	SetDefaultLocationBoostThunk,
	SetDefaultLocationThunk,
	SetDefaultLookingForThunk,
	SetPropertyBoostDataStateThunk,
	SetPropertyBoostDetailStateThunk,
	SetPropertyBoostLocationStateThunk,
	// PropertyBoost
	SetPropertyBoostMobileStepStateThunk,
	SetPropertyBoostModalStateThunk,
	SetPropertyBoostPointsStateThunk,
	SetPropertyBoostSnackStateThunk,
	SetPropertyBoostedByStateThunk,
	SetPropertyLoadingStateThunk,
	SetintroThunk,
	ShowContactDetailsPropertyThunk,
	ShowContactDetailsServiceThunk,
	StoreLeadsThunk,
	UpdateAboutDeveloperThunk,
	UpdateAboutLocationPropertyThunk,
	UpdateAboutPropertyThunk,
	UpdateAiOverviewThunk,
	UpdateAmenitiesThunk,
	UpdateBusinessAboutThunk,
	UpdateBusinessBrokerageThunk,
	UpdateBusinessExpertiseThunk,
	UpdateBusinessFAQsThunk,
	UpdateBusinessHiringThunk,
	UpdateBusinessHonorsThunk,
	UpdateBusinessLanguagesThunk,
	UpdateBusinessLicencesThunk,
	UpdateBusinessProfileAboutDetailsThunk,
	UpdateBusinessProfileAreasOfOperationThunk,
	UpdateBusinessProfileLanguagesThunk,
	UpdateBusinessProfileLeadsNotesThunk,
	UpdateBusinessProfileLeadsStatusThunk,
	UpdateBusinessProfileListingsCountThunk,
	UpdateBusinessProfilePictureThunk,
	UpdateBusinessProfileTitleThunk,
	UpdateBusinessServicesThunk,
	UpdateBusinessWhyChooseUsThunk,
	UpdateConfigAndAreaThunk,
	UpdateOverviewThunk,
	UpdatePropertyDetailsThunk,
	UpdatePropertyImageThunk,
	UpdatePropertyTitleThunk,
	UpdateServiceAreasOfOperationThunk,
	UpdateServiceDetailsThunk,
	UpdateServiceImageThunk,
	UpdateServiceOverViewThunk,
	UpdateServiceProviderInfoThunk,
	UpdateServiceSpecificationThunk,
	UpdateServiceTitleThunk,
	UpdateUserAboutDetailsThunk,
	UpdateUserDetailThunk,
	UpdateUserProfileLeadsNotesThunk,
	UpdateUserProfileLeadsStatusThunk,
	UpdateUserSkillsThunk,
	UpdateVicinityThunk,
	acceptEmployeeRequestThunk,
	addAdminUserDetailsThunk,
	addBusinessCoverImageThunk,
	addBusinessProfilePictureThunk,
	addCoverImageThunk,
	addProfilePictureThunk,
	addPropertyImagesThunk,
	addSaveEntityBusinessProfileThunk,
	addSaveEntityUserProfileThunk,
	addServiceImagesThunk,
	bookmarkThunk,
	businessDashboardAddAdminUserDetailsThunk,
	businessDashboardDeleteAdminUserDetailsThunk,
	businessProfileListTabViewThunk,
	cancelBoostThunk,
	changeAutoplayVideosThunk,
	changeDataTuningThunk,
	changeDefaultVideoQualityThunk,
	changeMeThunk,
	changeRecievePromotionalEmailsThunk,
	commentSwrThunk,
	createNewBusinessProfileThunk,
	createNewOFBPropertyThunk,
	createNewOFBPropertyUserThunk,
	createNewPropertyWithUploadToolThunk,
	deleteAdminUserDetailsThunk,
	deleteBusinessCoverImageThunk,
	deleteBusinessProfilePictureThunk,
	deleteBusinessProfileThunk,
	deleteEmployeeRequestThunk,
	deletePostUserThunk,
	deletePropertyDetailsThunk,
	deletePropertyImageThunk,
	deletePropertyOFBDetailsThunk,
	deletePropertyOFBUserThunk,
	deleteReviewThunk,
	deleteServiceDetailsThunk,
	deleteServiceImageThunk,
	deleteUserCoverImageThunk,
	deleteUserProfilePictureThunk,
	fetchAboutSinglePropertyThunk,
	fetchAccountSettingsFilesCountThunk,
	fetchAccountSettingsPageThunk,
	fetchAccountSettingsProfileKycInfoThunk,
	fetchAccountSettingsRewardTransactionThunk,
	fetchAccountSettingsSubscriptionsPlansThunk,
	fetchAccountSettingsSubscriptionsThunk,
	fetchAccountSettingsUserBeegruPointstransactionsThunk,
	fetchAccountSettingsUserCashtransactionsThunk,
	fetchAccountSettingsUserDataThunk,
	fetchBoostBusinessProfilePageThunk,
	fetchBoostPostPageThunk,
	fetchBoostPropertyPageThunk,
	fetchBoostServicePageThunk,
	fetchBoostUserProfilePageThunk,
	fetchBusinessDashboardBoostsMobileThunk,
	fetchBusinessDashboardLeadsDesktopThunk,
	fetchBusinessDashboardLeadsMobileThunk,
	fetchBusinessDashboardLeadsOverviewThunk,
	fetchBusinessDashboardListingsThunk,
	fetchBusinessDashboardOFBsOverviewThunk,
	fetchBusinessDashboardProfileDataThunk,
	fetchBusinessDashboardPropertiesOverviewThunk,
	fetchBusinessDashboardReviewsMobileThunk,
	fetchBusinessDashboardSavedItemsThunk,
	fetchBusinessDashboardServicesOverviewThunk,
	fetchBusinessProfileAboutDetailsThunk,
	fetchBusinessProfileAdminsDetailsAndEmployeeRequestThunk,
	fetchBusinessProfileAnalyticsOFBsThunk,
	fetchBusinessProfileAnalyticsPostThunk,
	fetchBusinessProfileAnalyticsPropertyThunk,
	fetchBusinessProfileAnalyticsServiceThunk,
	fetchBusinessProfileBrokerageThunk,
	fetchBusinessProfileExpertiseThunk,
	fetchBusinessProfileFaqsThunk,
	fetchBusinessProfileFollowerAndFollowingThunk,
	fetchBusinessProfileGivenReviewsThunk,
	fetchBusinessProfileHiringThunk,
	fetchBusinessProfileHonorsAndAwardsThunk,
	fetchBusinessProfileImagesThunk,
	fetchBusinessProfileLanguagesThunk,
	fetchBusinessProfileLeadsThunk,
	fetchBusinessProfileLicencesAndCertificatesThunk,
	fetchBusinessProfileListingOFBThunk,
	fetchBusinessProfileListingPropertyThunk,
	fetchBusinessProfileListingServiceThunk,
	fetchBusinessProfileNoOfEmployeesThunk,
	fetchBusinessProfilePostsThunk,
	fetchBusinessProfilePublishedPropertiesThunk,
	fetchBusinessProfilePublishedServicesThunk,
	fetchBusinessProfileReviewsThunk,
	fetchBusinessProfileSavedOFBThunk,
	fetchBusinessProfileSavedPostThunk,
	fetchBusinessProfileSavedPropertyThunk,
	fetchBusinessProfileSavedServiceThunk,
	fetchBusinessProfileServiceOfferedThunk,
	fetchBusinessProfileSubscriptionThunk,
	fetchBusinessProfileUserViewListingsThunk,
	fetchBusinessProfileWhychooseThunk,
	fetchDevelopersThunk,
	fetchFaqsThunk,
	fetchGlobalSearchResultsThunk,
	fetchHomePageFeaturedPropertiesThunk,
	fetchHomePageHotPropertiesBuyLocalStorageThunk,
	fetchHomePageHotPropertiesOfbLocalStorageThunk,
	fetchHomePageHotPropertiesRentLocalStorageThunk,
	fetchHomePageHotPropertiesThunk,
	fetchHomePageThunk,
	// fetchHomePageTrendingUsersThunk,
	fetchHomePageTopBusinessProfilesThunk,
	fetchHomePageTopServicesThunk,
	// fetchHomePageTrendingServicesThunk,
	fetchHomePageTopUsersThunk,
	fetchLeadsByIdThunk,
	fetchNearbyPropertiesThunk,
	fetchNeighbourhoodReviewsPageThunk,
	fetchNotificationPageThunk,
	fetchOnBoardingBusinessTitleThunk,
	fetchOneTimeRewardsThunk,
	fetchPropertyCardThunk,
	fetchPropertyDataReviewsPageThunk,
	fetchPropertyReviewsPageThunk,
	fetchPropertyUserDataReviewsPageThunk,
	fetchRealtimeNotificationThunk,
	fetchReceiptPageThunk,
	fetchRecurringRewardsThunk,
	fetchSearchThunk,
	fetchServiceDataReviewsPageThunk,
	fetchServiceReviewsPageThunk,
	fetchServiceUserDataReviewsPageThunk,
	fetchSingleBusinessProfileThunk,
	fetchSingleFaqThunk,
	fetchSinglePostThunk,
	fetchSinglePropertyAboutLocationThunk,
	fetchSinglePropertyAmenitiesThunk,
	fetchSinglePropertyConfigAndAreaThunk,
	fetchSinglePropertyCreatorThunk,
	fetchSinglePropertyDetailsThunk,
	fetchSinglePropertyDeveloperThunk,
	fetchSinglePropertyHotPropertiesThunk,
	fetchSinglePropertyImagesThunk,
	fetchSinglePropertyLocationCoordinatesThunk,
	fetchSinglePropertyNeighbourhoodReviewsThunk,
	fetchSinglePropertyOverviewThunk,
	fetchSinglePropertyReviewsThunk,
	fetchSinglePropertyThunk,
	fetchSinglePropertyTrendingPropertiesThunk,
	fetchSinglePropertyVicinityThunk,
	fetchSingleServiceAreasOfOperationThunk,
	fetchSingleServiceCreatorDetailsThunk,
	fetchSingleServiceDetailsThunk,
	fetchSingleServiceImagesThunk,
	fetchSingleServiceMapViewThunk,
	fetchSingleServiceOverviewThunk,
	fetchSingleServicePageThunk,
	fetchSingleServiceProviderThunk,
	fetchSingleServiceReviewsThunk,
	fetchSingleServiceSpecificationsThunk,
	fetchSingleServiceTopServicesThunk,
	fetchSingleServiceTrendingServicesThunk,
	fetchSingleUserAnalyticsThunk,
	fetchSingleUserProfileAboutDetailsThunk,
	// fetchHomePageTrendingBusinessProfilesThunk,
	fetchSingleUserProfileCoverImageAndProfilePictureThunk,
	fetchSingleUserProfileFollowersAndFollowingsThunk,
	fetchSingleUserProfileGivenReviewsThunk,
	fetchSingleUserProfileListingsThunk,
	fetchSingleUserProfilePostsThunk,
	fetchSingleUserProfileReviewsThunk,
	fetchSingleUserProfileThunk,
	fetchSingleUserSavedItemsThunk,
	fetchSingleUserSavedOFBThunk,
	fetchSingleUserSavedPostThunk,
	fetchSingleUserSavedPropertiesThunk,
	fetchSingleUserSavedServiceThunk,
	fetchUserProfileLeadsThunk,
	followUserThunk,
	loadMoreGlobalSearchPostsThunk,
	loadMoreGlobalSearchResultsThunk,
	loadMoreNotificationPageThunk,
	loadMoreOfbPropertyThunk,
	loadMorePropertiesThunk,
	loadMoreSavedOFBThunk,
	loadMoreSavedPostsThunk,
	loadMoreSavedPropertiesThunk,
	loadMoreSavedServicesThunk,
	loadMoreServicesThunk,
	loadMoreUserPropertiesThunk,
	loadMoreUserSavedOFBThunk,
	loadMoreUserSavedPostsThunk,
	loadMoreUserSavedPropertiesThunk,
	loadMoreUserSavedServicesThunk,
	postSwrForBusinessThunk,
	postSwrForUserThunk,
	profileContextThunk,
	reportPostThunk,
	searchBusinessProfileOfbPropertiesThunk,
	searchBusinessProfilePropertiesThunk,
	searchBusinessProfileServicesThunk,
	searchOfbPropertyListingsThunk,
	searchPropertyListingsThunk,
	searchServiceListingsThunk,
	searchUserPropertiesThunk,
	searchUserPropertyListingsThunk,
	setAccountSettingsSnackbarThunk,
	setActionForboomsThunk,
	setAnalyticsTabValueThunk,
	setBottomBuyBeegruPointsThunk,
	setBusinessDashboardAdminLoadingThunk,
	setBusinessDashboardAnalyticsFilterEndDateXThunk,
	setBusinessDashboardAnalyticsFilterGraphDurationXThunk,
	setBusinessDashboardAnalyticsFilterGraphTypeYThunk,
	setBusinessDashboardAnalyticsFilterListingIdThunk,
	setBusinessDashboardAnalyticsFilterListingTypeThunk,
	setBusinessDashboardAnalyticsFilterStartDateXThunk,
	setBusinessDashboardAnalyticsGraphSourceThunk,
	setBusinessDashboardAnalyticsLoadingThunk,
	setBusinessDashboardBoostsFilterListingTypeThunk,
	setBusinessDashboardBoostsFilterPageNumberThunk,
	setBusinessDashboardBoostsFilterSortFieldThunk,
	setBusinessDashboardBoostsFilterSortOrderThunk,
	setBusinessDashboardBoostsFilterStatusThunk,
	setBusinessDashboardBoostsInitialLoadDoneThunk,
	setBusinessDashboardBoostsLoadingThunk,
	setBusinessDashboardBoostsTriggerNewSearchThunk,
	setBusinessDashboardCurrentScreenThunk,
	setBusinessDashboardLeadsFilterListingTypeThunk,
	setBusinessDashboardLeadsFilterPageNumberThunk,
	setBusinessDashboardLeadsFilterSortFieldThunk,
	setBusinessDashboardLeadsFilterSortOrderThunk,
	setBusinessDashboardLeadsFilterStatusThunk,
	setBusinessDashboardLeadsInitialLoadDoneThunk,
	setBusinessDashboardLeadsLoadingThunk,
	setBusinessDashboardLeadsTriggerNewSearchThunk,
	setBusinessDashboardListingsFilterListingTypeThunk,
	setBusinessDashboardListingsFilterPageNumberThunk,
	setBusinessDashboardListingsFilterSortFieldThunk,
	setBusinessDashboardListingsFilterSortOrderThunk,
	setBusinessDashboardListingsFilterStatusThunk,
	setBusinessDashboardListingsFilterTransactionTypeThunk,
	setBusinessDashboardListingsInitialLoadDoneThunk,
	setBusinessDashboardListingsLoadingThunk,
	setBusinessDashboardListingsTriggerNewSearchThunk,
	setBusinessDashboardReviewsFilterPageNumberThunk,
	setBusinessDashboardReviewsFilterSortFieldThunk,
	setBusinessDashboardReviewsFilterSortOrderThunk,
	setBusinessDashboardReviewsInitialLoadDoneThunk,
	setBusinessDashboardReviewsTriggerNewSearchThunk,
	setBusinessDashboardSavedItemsFilterListingTypeThunk,
	setBusinessDashboardSavedItemsFilterPageNumberThunk,
	setBusinessDashboardSavedItemsInitialLoadDoneThunk,
	setBusinessDashboardSavedItemsLoadingThunk,
	setBusinessDashboardSavedItemsTriggerNewSearchThunk,
	setBusinessDashboardUserViewTabValueThunk,
	setBusinessProfileAboutDesignationThunk,
	setBusinessProfileAboutEducationThunk,
	setBusinessProfileAboutIndustryTypeThunk,
	setBusinessProfileAboutLongDescriptionThunk,
	setBusinessProfileAboutOfficeAddressThunk,
	setBusinessProfileAboutOrganizationThunk,
	setBusinessProfileAboutShortDescriptionThunk,
	setBusinessProfileAboutWebsiteUrlThunk,
	setBusinessProfileAboutYearEstablishedThunk,
	setBusinessProfileBrokerageThunk,
	setBusinessProfileEmailThunk,
	setBusinessProfileExpertiseThunk,
	setBusinessProfileFAQThunk,
	setBusinessProfileHonorsAndAwardsThunk,
	setBusinessProfileLanguagesThunk,
	setBusinessProfileLicencesAndCertificatesThunk,
	setBusinessProfileNoOfEmployeesThunk,
	setBusinessProfilePhoneCountryCodeThunk,
	setBusinessProfilePhoneNumberThunk,
	setBusinessProfileServicesOfferedTagsThunk,
	setBusinessProfileSnackbarThunk,
	setBusinessProfileVacanciesThunk,
	setBusinessProfileWhyChooseUsThunk,
	setCreateBoostThunk,
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewPropertyCategoryTypeThunk,
	setCreateNewPropertyCurrentPageThunk,
	setCreateNewPropertyDefaultLocationThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxCarpetAreaThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinCarpetAreaThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyOverviewThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewPropertyUploadImagesThunk,
	setCreateNewServiceCategoryThunk,
	setCreateNewServiceCurrentPageThunk,
	setCreateNewServiceDefaultLocationThunk,
	setCreateNewServiceMaxPriceThunk,
	setCreateNewServiceMinPriceThunk,
	setCreateNewServiceOtherSubTypeThunk,
	setCreateNewServiceProviderNameThunk,
	setCreateNewServiceShowPriceThunk,
	setCreateNewServiceSubTypeThunk,
	setCreateNewServiceTypeThunk,
	setCreateNewServiceUploadImagesThunk,
	setCreateNewePropertyPossessionDateThunk,
	setCreatePropertySelectedImagesUrlThunk,
	setDataBeforeEditThunk,
	setDefaultProfileContextThunk,
	setDrawerScreenThunk,
	setFaqSearchResultsThunk,
	setHomeLoadingThunk,
	setHomeLocationThunk,
	setHomePageBusinessProfileLoadingThunk,
	setHomePagePropertiesLoadingThunk,
	setImageCategoryThunk,
	setImagesIndexThunk,
	setIsLoadingThunk,
	setIsSearchingThunk,
	setLanguageThunk,
	setListingTabValueThunk,
	setLoadingThunk,
	setOnBoardingDetailsThunk,
	setPostPropertyWithUploadToolSnackbarErrorThunk,
	setProfileContextThunk,
	setPropertyImagesUploadCompletedThunk,
	setPropertySnackbarThunk,
	setSaveTabValueThunk,
	setSavedIsPublicThunk,
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	setSearchOFBThunk,
	setSearchPropertiesThunk,
	setSearchResultsPageNumberThunk,
	setSearchServiceThunk,
	setSectionToEditThunk,
	setServiceImagesUploadCompletedThunk,
	setServiceSnackbarThunk,
	setSinglePropertyAboutLocationThunk,
	setSinglePropertyAboutThunk,
	setSinglePropertyAgeThunk,
	setSinglePropertyAmenitiesThunk,
	setSinglePropertyAvailabiltyThunk,
	setSinglePropertyCategoryTypeThunk,
	setSinglePropertyDescriptionThunk,
	setSinglePropertyDeveloperNameThunk,
	setSinglePropertyEstablishedYearThunk,
	setSinglePropertyFloorNoThunk,
	setSinglePropertyFurnishingThunk,
	setSinglePropertyLandAreaThunk,
	setSinglePropertyLandFacingThunk,
	setSinglePropertyManualOverviewThunk,
	setSinglePropertyMaxBalconiesThunk,
	setSinglePropertyMaxBathroomsThunk,
	setSinglePropertyMaxBedroomsThunk,
	setSinglePropertyMaxCarpetAreaThunk,
	setSinglePropertyMaxFloorsThunk,
	setSinglePropertyMaxPriceThunk,
	setSinglePropertyMaxSuperBuiltUpAreaThunk,
	setSinglePropertyMinBalconiesThunk,
	setSinglePropertyMinBathroomsThunk,
	setSinglePropertyMinBedroomsThunk,
	setSinglePropertyMinCarpetAreaThunk,
	setSinglePropertyMinPriceThunk,
	setSinglePropertyMinSuperBuiltUpAreaThunk,
	setSinglePropertyNoOfSeatsThunk,
	setSinglePropertyNoOfUnitsThunk,
	setSinglePropertyOtherPlotDimensionsThunk,
	setSinglePropertyOverviewThunk,
	setSinglePropertyPlotDimensionsThunk,
	setSinglePropertyPossessionDateThunk,
	setSinglePropertySecurityDepositThunk,
	setSinglePropertySubTypeThunk,
	setSinglePropertyTagsThunk,
	setSinglePropertyTransactionTypeThunk,
	setSinglePropertyTypeThunk,
	setSinglePropertyUnitFacingThunk,
	setSinglePropertyVicinityThunk,
	setSingleServiceAreasOfOperationThunk,
	setSingleServiceCategoryThunk,
	setSingleServiceLocationCoordinatesThunk,
	setSingleServiceLocationPincodeThunk,
	setSingleServiceLocationTitleThunk,
	setSingleServiceMaxPriceThunk,
	setSingleServiceMinPriceThunk,
	setSingleServiceOtherSubtypeThunk,
	setSingleServiceOverviewThunk,
	setSingleServiceProviderDescriptionThunk,
	setSingleServiceProviderEstablishedYearThunk,
	setSingleServiceProviderNameThunk,
	setSingleServiceShowPriceThunk,
	setSingleServiceSpecificationThunk,
	setSingleServiceSubtypeThunk,
	setSingleServiceTagsThunk,
	setSingleServiceTypeThunk,
	setStartBoostThunk,
	setTabValueThunk,
	setThemeThunk,
	setTransactionTypeThunk,
	setUserLoadingThunk,
	setUserLocationThunk,
	setUserSavedItemsFilterListingTypeThunk,
	setUserSavedItemsFilterPageNumberThunk,
	setUserSavedItemsInitialLoadDoneThunk,
	setUserSavedItemsLoadingThunk,
	setUserSavedItemsTriggerNewSearchThunk,
	switchBusinessDashboardContantDetailsThunk,
	unfollowUserThunk,
	updateBeegrupointsAfterPurchaseThunk,
	updateBlastsThunk,
	updateBoomsThunk,
	updateBoostThunk,
	updateBusinessProfileKycInfoThunk,
	updateLocationPropertyThunk,
	updateNoOfEmployeesThunk,
	updateNotificationViewedThunk,
	updatePersonalInfoThunk,
	updateReduxSessionThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateServiceLocationThunk,
	updateUserKycInfoThunk,
	updatedPublishedPropertiesThunk,
	updatedPublishedServicesThunk
} from "./thunks";

/*

& Now, we'll export the default wrapper function. This is the function that will be used to wrap our NextJS app with redux.

* This wrapper is used in pages/_app.tsx to wrap the app and when using getStaticProps() or getServerSideProps() in individual page files in pages/<filename>.tsx.

*/

export const wrapper = createWrapper<AppStore>(makeStore, {
	/*

    TODO Remove all debug flags before going live.

    */

	debug: false,

	/*

    * These are custom serialization and deserialization functions that are used to serialize and deserialize the state. While what we're doing here is not necessary, we'll leave these here in case we decide to turn redux-magic into redux-black-magic in the future. He-who-must-not-be-named will certainly be pleased.

    */

	serializeState: (state: AppState) => JSON.stringify(state),
	deserializeState: (state: string) => JSON.parse(state),
});

/*

& Finally, we're going to export a function that will allow us to choose a slice. Since we are using one global slice, all the extra bits that allow the choosing to take place are not necessary and have been omitted from this description as such.

*/

export const sliceSelector = () => (state: AppState) => state?.[globalSlice.name];
