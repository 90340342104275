/* State Interface Imports */

import StateInterface from "../state-interface";

/* Default State Imports */

import { business_profile } from "./sub-state-data";

/* Business Dashboard Page Default State Data */

const business_dashboard_data: StateInterface["business_dashboard"] = {
	business_profile: business_profile,
	business_profile_data_loading: true,
	user_view_tab_value: 0,
	search_listings_response: {
		listing_ids: [],
		trigger_new_search: false,
		listings_loading: true,
		initial_load_done: false,
		listings_count: 0,
		filter_status: "all",
		filter_listing_type: "property",
		filter_transaction_type: "all",
		filter_sort_field: "created_at",
		filter_sort_order: -1,
		filter_page_number: 1,
		listings_overview: {
			total_properties_count: 0,
			published_count: 0,
			in_review_count: 0,
			boosted_count: 0,
			listings_overview_loaded: false,
			sale_count: 0,
			rent_count: 0,
		},
	},
	search_leads_response: {
		lead_ids: [],
		trigger_new_search: false,
		leads_loading: true,
		initial_load_done: false,
		leads_count: 0,
		filter_status: "all",
		filter_listing_type: "all",
		filter_sort_field: "created_at",
		filter_sort_order: -1,
		filter_page_number: 1,
		leads_overview: {
			new_leads_count: 0,
			follow_up_leads_count: 0,
			site_visit_leads_count: 0,
			closed_leads_count: 0,
			not_interested_leads_count: 0,
			total_leads: 0,
			leads_overview_loaded: false,
		},
	},
	search_reviews_response: {
		reviews_ids: [],
		trigger_new_search: false,
		reviews_loading: true,
		initial_load_done: false,
		reviews_count: 0,
		filter_sort_field: "created_at",
		filter_sort_order: -1,
		filter_page_number: 1,
		did_user_already_review: false,
	},
	leads: [],
	search_saved_items_response: {
		saved_items_ids: [],
		trigger_new_search: false,
		saved_items_loading: true,
		initial_load_done: false,
		saved_items_count: 0,
		filter_listing_type: "property",
		filter_page_number: 1,
	},
	admins: {
		admins: [],
		is_admin_fetching: true,
		admin_loading: true,
	},
	search_analytics_response: {
		anal_graph_source: "",
		analytics_loading: false,
		filter_listing_type: "property",
		filter_listing_id: "",
		filter_graph_type_y: "leads",
		filter_graph_duration_x: "week",
		filter_start_date_x: "",
		filter_end_date_x: "",
		graph_screen_stat_total: 0,
		graph_screen_temp_data: null,
		graph_screen_x_axis: [],
		graph_screen_y_axis: [],
		overview_profile_views_loading: true,
		overview_profile_views: 0,
		overview_total_listing_leads_loading: true,
		overview_total_listing_leads: 0,
		overview_leads_difference_since_last_week: 0,
		overview_leads_is_difference_negative: false,
		overview_total_listing_views_loading: true,
		overview_total_listing_views: 0,
		overview_views_difference_since_last_week: 0,
		overview_views_is_difference_negative: true,
		overview_total_listing_saves: 0,
		overview_saves_difference_since_last_week: 0,
		overview_most_viewed_listing_loading: true,
		overview_most_viewed_listing_id: "",
		overview_most_viewed_listing_views: 0,
		overview_most_viewed_listing_views_difference_since_last_week: 0,
		overview_most_viewed_listing_is_difference_negative: false,
		overview_most_saved_listing_loading: true,
		overview_most_saved_listing_id: "",
		overview_most_saved_listing_saves: 0,
		overview_most_saved_listing_saves_difference_since_last_week: 0,
		overview_most_saved_listing_is_difference_negative: false,
		overview_most_leads_generated_listing_loading: true,
		overview_most_leads_generated_listing_id: "",
		overview_most_leads_generated_listing_leads: 0,
		overview_most_leads_generated_listing_leads_difference_since_last_week: 0,
		overview_most_leads_generated_listing_is_difference_negative: false,
	},
	search_boosts_response: {
		boost_ids: [],
		trigger_new_search: false,
		boosts_loading: true,
		initial_load_done: false,
		boosts_count: 0,
		filter_status: "all",
		filter_listing_type: "property",
		filter_sort_field: "created_at",
		filter_sort_order: -1,
		filter_page_number: 1,
		boosts_overview: {
			leads_count: 0,
			views_count: 0,
			saves_count: 0,
		},
	},
	current_screen: "home",
	anal_graph_source: "",
};

export const business_dashboard = business_dashboard_data;

export default business_dashboard_data;
